import axios from 'axios';


export const AxioslibUser = axios.create({

    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const AxioslibToken = axios.create({

    baseURL: process.env.REACT_APP_AUTH_TOKEN,
    headers: {
        'Content-Type': 'application/json'
    }
});
export const AxioslibGenerateToken = axios.create({

    baseURL: process.env.REACT_APP_GENERATE_TOKEN,
    headers: {
        'Content-Type': 'application/json'
    }
});
export const AxioslibServerUpgrade = axios.create({

    baseURL: process.env.REACT_APP_SERVER_UPGRADE,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const AxiosAzureAccessToken = axios.create({

    baseURL: process.env.REACT_APP_AZURE_ACCESS_TOKEN,
    headers: {
        'Content-Type': 'application/json'
    }
});

