import Login from './component/Login/login'
import Dashboard from './component/Home/dashboard'
import Servermanagement from './component/Admin/adminmanagement'
import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from '../src/lib/privateRoute'
function App() {
 
  return (
    <BrowserRouter >
      <Switch>
        <Route path="/:reportId/:datasetId" exact component={Dashboard} />
          <Route path="/dashboard/:reportId/:datasetId" exact component={Dashboard} />
          <Route path="/admin-manage"  component={Servermanagement} />
      </Switch>
    </BrowserRouter >
  );
}

export default (App);