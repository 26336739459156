import { AxioslibUser, AxioslibToken, AxiosAzureAccessToken, AxioslibGenerateToken, AxioslibServerUpgrade } from "../../lib/axiolib-api";
import queryString from 'query-string';
import axios from 'axios';
export const checkUserLogin = (body) => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/LoginDetails`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getToken = (body) => {
  let credentials = {
    "client_id": process.env.REACT_APP_CLIENT_ID,
    "grant_type": process.env.REACT_APP_GRAND_TYPE,
    "scope": process.env.REACT_APP_SCOPE,
    "client_secret": process.env.REACT_APP_CLIENT_SECRET,
    "tenant_id": process.env.REACT_APP_TENANTID
  }
  return new Promise((resolve, reject) => {
    axios.post(`https://oshainsider-sandbox.techvantagesystems.com/getTokenDetails`, JSON.stringify(credentials))
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const azureAccessToken = (body) => {
  let credentials = {
    "client_id": process.env.REACT_APP_CLIENT_ID,
    "grant_type": process.env.REACT_APP_GRAND_TYPE,
    "scope": process.env.REACT_APP_AZURE_SCOPE,
    "client_secret": process.env.REACT_APP_CLIENT_SECRET,
    "tenant_id": process.env.REACT_APP_TENANTID
  }
  return new Promise((resolve, reject) => {
    axios.post(`https://oshainsider-sandbox.techvantagesystems.com/getTokenDetails`, JSON.stringify(credentials))
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
// export const azureAccessToken = (body, tid) => {
//   let details = queryString.stringify(body)
//   return new Promise((resolve, reject) => {
//     AxiosAzureAccessToken.post(`${tid}/oauth2/v2.0/token`, body, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//       },

//     }).then((response) => {
//       if (response) {
//         resolve(response);
//       } else {
//         resolve({ status: 101, message: "No response from the server" });
//       }
//     })
//       .catch((e) => {
//         resolve({ status: 500, message: "No response from the server" });
//       });
//   });
// };
export const getAccessToken = (body, authToken) => {
  return new Promise((resolve, reject) => {
    AxioslibGenerateToken.post(`GenerateToken`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        resolve(response);
      } else {
        resolve({ status: 101, message: "No response from the server" });
      }
    })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const getEmbededUrl = (authToken,reportId) => {
  return new Promise((resolve, reject) => {
    AxioslibGenerateToken.get(`groups/${process.env.REACT_APP_GROUP_ID}/reports/${reportId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        resolve(response);
      } else {
        resolve({ status: 101, message: "No response from the server" });
      }
    })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const upgrateServerTime = (authToken, AccessToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.post(`${process.env.REACT_APP_SUBSCRIPTION_ID}/resourceGroups/${process.env.REACT_APP__APP_RESOURCE_GROUP_NAME}/providers/Microsoft.PowerBIDedicated/capacities/${process.env.REACT_APP_DEDICATED_CAPACITY_NAME}/resume?api-version=2021-01-01`, AccessToken, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    }).then((response) => {
      console.log(response);


      resolve({ status: 200, message: "Server started" });

    })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};


export const suspendServerTime = (authToken, AccessToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.post(`${process.env.REACT_APP_SUBSCRIPTION_ID}/resourceGroups/${process.env.REACT_APP__APP_RESOURCE_GROUP_NAME}/providers/Microsoft.PowerBIDedicated/capacities/${process.env.REACT_APP_DEDICATED_CAPACITY_NAME}/suspend?api-version=2021-01-01`, AccessToken, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    }).then((response) => {
      console.log(response);

      resolve({ status: 200, message: "Server stoped" });

    })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getServerStatus = (authToken) => {
  return new Promise((resolve, reject) => {
    AxioslibServerUpgrade.get(`/a3875509-b954-4ce4-9631-cd67d6f519e8/resourceGroups/GSEP-POC/providers/Microsoft.PowerBIDedicated/capacities/pbea1gsep?api-version=2021-01-01`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: authToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        resolve(response);
      } else {
        resolve({ status: 101, message: "No response from the server" });
      }
    })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};






